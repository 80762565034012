<template>
  <div class="loader-wrapper" :class="bgClass">
    <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
    <div v-if="this.loaderText !== ''" class="loader-text">{{ this.loaderText }}</div>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  props: {
    dark: Boolean,
    text: String
  },
  data () {
    return {
      bgClass: this.dark === true ? 'bg-dark-gray' : 'bg-gray',
      loaderText: this.text
    }
  }
}
</script>
<style lang="scss" scoped>
  .loader-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img{
      margin-bottom: 20px;
    }

    .loader-text{
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
    }
  }
</style>
